import { useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

interface AuthCheckProps {
  baseUrl?: string;
}

const AuthCheck = ({ baseUrl }: AuthCheckProps) => {
  const navigate = useNavigate();

  useLayoutEffect(() => {
    let token = localStorage.getItem('token');
    if (token) {
      // Ajoutez le token à l'en-tête de la demande
      axios.defaults.withCredentials = true;

      // Effectuez l'appel API
      axios.post(baseUrl + '/api/auth/refresh').then(response => {
        navigate("/home/dashboard/");
      })
    }
  }, []);

  return null;
};

export default AuthCheck;

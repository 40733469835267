import { NavLink, useLocation } from 'react-router-dom';
import { Breadcrumb, Menu, Dropdown } from 'antd';
import DropdownList from '../dropdown/DropdownList';

const ResumeNav = () => {
    const location = useLocation().pathname;

    // Détermine le contenu des éléments de menu en fonction du chemin actuel
    const getMenuItemLabel = () => {
        if (location === "/home/dashboard/") {
            return (
                <DropdownList URL='/home/list-all/'>
                    <span className="material-symbols-outlined">format_list_bulleted</span> Listes
                </DropdownList>
            );
        } else if (location === "/home/List-all/") {
            return (
                <DropdownList URL='/home/dashboard/'>
                    <span className="material-symbols-outlined">subject</span> Tableau de bord
                </DropdownList>
            );
        } else {
            return null;
        }
    };

    const menuItems = [
        {
            key: '1',
            label: getMenuItemLabel(),
        },
    ];

    return (
        <div>
            <div className='resume_nav'>
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <NavLink to="/home/dashboard/">
                            <div className="navigation_home">
                                <span className="material-symbols-outlined">home</span>
                                <p>Accueil</p>
                            </div>
                        </NavLink>
                    </Breadcrumb.Item>
                    {(location == "/list/add/") && (
                        <Breadcrumb.Item>
                            <NavLink to="/list/add/">
                                <div className="navigation_home">
                                    <span className="material-symbols-outlined">box_edit</span>                                    <p>Ajout d'une liste</p>
                                </div>
                            </NavLink>
                        </Breadcrumb.Item>
                    )}
                    {(location === "/home/dashboard/" || location === "/home/list-all/") && (
                        <Breadcrumb.Item>
                            <Dropdown overlay={<Menu items={menuItems} />}>
                                <div className="navigation_home">
                                    {location === "/home/dashboard/" && (
                                        <>
                                            <span className="material-symbols-outlined">dashboard</span> Tableau de bord
                                        </>
                                    )}
                                    {location === "/home/list-all/" && (
                                        <>
                                            <span className="material-symbols-outlined">format_list_bulleted</span> Listes
                                        </>
                                    )}
                                    <svg viewBox="64 64 896 896" focusable="false" data-icon="down" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                                        <path d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z"></path>
                                    </svg>
                                </div>
                            </Dropdown>
                        </Breadcrumb.Item>
                    )}
                </Breadcrumb>
            </div>
        </div>
    );
};

export default ResumeNav;
import NavBarMobile from '../../components/navigation/NavBarMobile'
import NavBarDesktop from '../../components/navigation/NavBarDesktop';
import HeaderDesktop from '../../components/header/HeaderDesktop';
import HeaderMobile from '../../components/header/HeaderMobile';
import CardsList from '../../components/cards/CardsList';
import ResumeNav from '../../components/navigation/ResumeNav';
import { NavLink } from 'react-router-dom';
import ButtonRoundHeader from '../../components/button/ButtonRoundHeader';
import ButtonLong from '../../components/button/ButtonLong';
import { Tooltip } from 'antd';
import CardsMove from '../../components/add/AddCards';

const AddList = () => {
    return (
        <div>
            <NavBarMobile />
            <NavBarDesktop />
            <HeaderDesktop />
            <HeaderMobile />
            <section className='page_content'>
                <ResumeNav />
                <div className='header_list_home'>
                    <h1>Créer une liste</h1>
                    <div className='header_list_home_action'>
                        <NavLink to='/list/add/'>
                            <Tooltip
                                title="Ajout d'une carte"
                                trigger='hover'
                                placement='bottom'
                            >
                                <ButtonRoundHeader>
                                    <span className="material-symbols-outlined">note_stack_add</span>
                                </ButtonRoundHeader>   
                            </Tooltip>                       
                        </NavLink>
                        <NavLink to='/home/list-all/'>
                            <ButtonLong>
                                Voir tout
                            </ButtonLong>
                        </NavLink>
                    </div>
                </div>
                <div className='organize_cards_desktop'>
                    <CardsMove />
                </div>
            </section>
        </div>
    );
};

export default AddList;
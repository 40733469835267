import React, { useState, useCallback } from 'react';
import { Card } from 'antd';
import { DndProvider, useDrag, useDrop, DragSourceMonitor, DropTargetMonitor } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

interface CardItem {
  id: string;
  content: string;
}

const initialCards: CardItem[] = [
  { id: '1', content: 'Card 1' },
  { id: '2', content: 'Card 2' },
  { id: '3', content: 'Card 3' },
];

const ItemType = 'CARD';

interface DragItem {
  index: number;
  id: string;
  type: string;
}

interface CardProps {
  card: CardItem;
  index: number;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
}

const DraggableCard: React.FC<CardProps> = ({ card, index, moveCard }) => {
  const ref = React.useRef<HTMLDivElement>(null);

  const [, drop] = useDrop({
    accept: ItemType,
    hover(item: unknown, monitor: DropTargetMonitor) {
      const dragItem = item as DragItem; // Assertion de type
      if (!ref.current) {
        return;
      }
      const dragIndex = dragItem.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset!.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveCard(dragIndex, hoverIndex);
      dragItem.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemType,
    item: { id: card.id, index },
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0.5 : 1;
  drag(drop(ref));

  return (
    <div ref={ref} style={{ opacity }}>
      <div id={`add_cards_${card.id}`}>

      </div>
      <Card style={{ marginBottom: 8 }}>
        {card.content}
      </Card>
    </div>
  );
};

const AddCards: React.FC = () => {
  const [cards, setCards] = useState(initialCards);

  const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
    const updatedCards = [...cards];
    const [removed] = updatedCards.splice(dragIndex, 1);
    updatedCards.splice(hoverIndex, 0, removed);
    setCards(updatedCards);
  }, [cards]);

  return (
    <DndProvider backend={HTML5Backend}>
      {cards.map((card, index) => (
        <DraggableCard key={card.id} index={index} card={card} moveCard={moveCard} />
      ))}
    </DndProvider>
  );
};

export default AddCards;
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Axios from './CallerBack';
import { toast } from 'react-toastify';

const AuthGuard = ({ children }: { children: any }) => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const checkAuthentication = async () => {
            const baseUrl = Axios.defaults.baseURL;
        
            axios.defaults.withCredentials = true;
            try {
                const isLoggedIn = await axios.post(baseUrl+'/api/auth/refresh');
                localStorage.setItem('token', isLoggedIn.data.token);
                setIsLoading(false);
                //const tokenChangeEvent = new Event('tokenChange');
                //window.dispatchEvent(tokenChangeEvent);
                /*if(isLoggedIn.data.activation == null || isLoggedIn.data.activation == '') {
                }else{
                   toast.warning("Vous n'avez pas encore validé votre email, veuillez le valider sur votre boîte mail.")
                }*/
            } catch (error) {
                //console.error(error);
                localStorage.removeItem('token');
                navigate('/');
                toast.error('Votre session a expiré, veuillez vous reconnecter.');
                setIsLoading(false);
            }
        };
        // Appeler checkAuthentication lorsque le composant est monté
        checkAuthentication();

    }, [navigate]);

    if (isLoading) {
        return <div></div>;
    }

    return children;
};

export default AuthGuard;
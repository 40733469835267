import React from "react";

type ButtonProps = {
  children: React.ReactNode;
  className?: string;
  [x: string]: any;
};

const ButtonTransparent = ({ children, className, ...props }: ButtonProps) => {
  return (
    <button className={`buttonTransparent ${className}`} {...props}>
      {children}
    </button>
  );
};

export default ButtonTransparent;
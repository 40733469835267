import { ChangeEvent } from 'react';
import axios from 'axios';
import Axios from '../../setting/CallerBack';
import { toast } from 'react-toastify';

const favoriteCatalogue = (event: ChangeEvent<HTMLInputElement>, id: number) => {
    event.preventDefault();

    const baseUrl = Axios.defaults.baseURL;

    const svg_filled = document.querySelector('.svg-filled-block-liste-' + id);
    const svg_filled_block = document.querySelector('.svg-filled-block-liste-' + id);

    if (svg_filled && svg_filled_block) {
      const hasSvgFilledBlock = svg_filled.classList.contains('svg-filled-block');
      const input_checkbox = document.querySelector<HTMLInputElement>('#Give-It-An-Id-' + id);

      if (hasSvgFilledBlock) {
        axios.post(baseUrl + '/api/favorite/remove/list/' + id)
          .then((response) => {
            svg_filled.classList.remove('svg-filled-block');
            svg_filled_block.classList.remove('svg-filled-block');
              const compteurListe = document.querySelector('#compteur_liste_' + id) as HTMLElement;
              if (compteurListe) {
                let counter = parseInt(compteurListe.innerText); // Convertit le texte en nombre
                counter--; // Décrémente le compteur
                compteurListe.innerText = counter.toString(); 
              }
              if (input_checkbox) {
                input_checkbox.checked = false;
              }
            toast.success('Cette liste a été supprimée de vos favoris');
          })
          .catch(() => {
            toast.error('Une erreur est survenue, veuillez réessayer.');
          });
      } else {
        axios.post(baseUrl + '/api/favorite/add/list/' + id)
          .then(() => {
            svg_filled.classList.add('svg-filled-block');
            svg_filled_block.classList.add('svg-filled-block');
              const compteurListe = document.querySelector('#compteur_liste_' + id) as HTMLElement;
              if (compteurListe) {
                let counter = parseInt(compteurListe.innerText); // Convertit le texte en nombre
                counter++; // Décrémente le compteur
                compteurListe.innerText = counter.toString(); 
              }
            if (input_checkbox) {
              input_checkbox.checked = true;
            }
            toast.success('Cette liste a été ajoutée à vos favoris');
          })
          .catch(() => {
            toast.error('Une erreur est survenue, veuillez réessayer.');
          });
      }
  }
};

export default favoriteCatalogue;
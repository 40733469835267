import React from 'react';
import { NavLink } from 'react-router-dom';

type ButtonProps = {
    children: React.ReactNode;
    URL?: string;
    className?: string;
    [x: string]: any;
  };
  
  const DropdownList = ({ children, URL, className, ...props }: ButtonProps) => {
    return (
        <NavLink to={`${URL}`} >
            <div className={`navigation_home ${className}`} {...props}>
                {children}
            </div>
        </NavLink>
    );
  };

export default DropdownList;
import React, { useState, useEffect, useLayoutEffect } from 'react';
import AuthPage from '../../components/authentication/AuthPage';
import { GoogleOutlined } from '@ant-design/icons';
import Axios from '../../setting/CallerBack';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import AuthCheck from '../../components/authentication/AuthCheck';

const Login = () => {
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        // Gérer la soumission du formulaire
        event.preventDefault();
        axios.defaults.withCredentials = true;

        axios.post(baseUrl+'/api/login', {
            username: username,
            password: password,
        })
        .then((response) => {
            toast.success('Connexion Réussie.');
            localStorage.setItem('token', response.data.token);
            navigate("/home/dashboard/");
        })
        .catch((error) => {
            toast.error('Identifiants incorrects, veuillez saisir de nouveau vos identifiants');
        });
    };

    const navigate = useNavigate();

    const baseUrl = Axios.defaults.baseURL;

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    
    return (
        <>
            <AuthCheck baseUrl={baseUrl ?? ''} />
            <AuthPage
                title="Connexion"
                fields={[
                    { 
                        name: 'username', 
                        type: 'text', 
                        label: 'Email/Pseudo', 
                        value: username, 
                        onChange: (e) => setUsername(e.target.value) 
                    },
                    { 
                        name: 'password', 
                        type: 'password', 
                        label: 'Mot de passe', 
                        value: password, 
                        onChange: (e) => setPassword(e.target.value) 
                    }
                ]}
                buttonText="Se connecter"
                onSubmit={handleSubmit}
                additionalContent={
                    <div className='connect_with'>
                        <p>Se connecter avec</p>
                        <GoogleOutlined/>
                    </div>
                }
            />
        </>
    );
};

export default Login;

import React, { useState } from 'react';
import AuthPage from '../../components/authentication/AuthPage';
import { GoogleOutlined } from '@ant-design/icons';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import AuthCheck from '../../components/authentication/AuthCheck';
import Axios from '../../setting/CallerBack';

const Register = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [pseudo, setPseudo] = useState('');
    const navigate = useNavigate();
    const baseUrl = Axios.defaults.baseURL;

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        axios.defaults.withCredentials = true;

        if (!validatePseudo(pseudo)) {
            return;
        }
        if (!validateEmail(username)) {
            return;
        }
        if (!validatePassword(password)) {
            return;
        }

        axios.post(baseUrl+'/api/register', {
            username,
            pseudo,
            password,
        })
        .then((response) => {
            toast.success('Bienvenue, ' + pseudo + ".");
            localStorage.setItem('token', response.data.token);
            navigate('/home/dashboard/');
        })
        .catch((error) => {
            console.error(error);
            if (error.response.status === 500) {
                if (error.response.data === 'Votre pseudo existe déjà, veuillez resaisir un pseudo.') {
                    toast.error('Votre pseudo existe déjà, veuillez resaisir un pseudo.');
                } else if (error.response.data === 'Votre email existe déjà, veuillez resaisir un email.') {
                    toast.error('Votre email existe déjà, veuillez resaisir un email.');
                } else {
                    toast.error('Erreur lors de l\'inscription, veuillez réessayer.');
                }
            } else if (error.response.status === 401) {
                toast.error('Le formulaire saisi n\'est pas valide, veuillez resaisir vos informations.');
            }
        });
    };

    const validateEmail = (email: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            toast.warning('Vous devez utiliser un email valide.');
            return false;
        }
        return true;
    };

    const validatePassword = (password: string) => {
        const minLength = 8;
        if (password.length < minLength) {
            toast.warning('Votre mot de passe doit faire plus de 8 caractères.');
            return false;
        }

        const containsNumber = /\d/.test(password);
        const containsLowercase = /[a-z]/.test(password);
        const containsUppercase = /[A-Z]/.test(password);
        //const containsSpecialChar = /[$&+,:;=?@#|'<>.^*()%!-]/.test(password);

        if (!containsNumber) {
            toast.warning('Votre mot de passe doit contenir au moins 1 chiffre.');
            return false;
        }
        if (!containsLowercase) {
            toast.warning('Votre mot de passe doit contenir au moins 1 minuscule.');
            return false;
        }
        if (!containsUppercase) {
            toast.warning('Votre mot de passe doit contenir au moins 1 majuscule.');
            return false;
        }
        return true;
    };

    const validatePseudo = (pseudo: string) => {
        const maxLength = 30;
        if (pseudo.length > maxLength || pseudo.length === 0) {
            toast.warning('Votre pseudo ne peut pas dépasser 30 caractères.');
            return false;
        }

        const containsSpecialChar = /[$&+,:;=?@#|'<>.^*()%!-]/.test(pseudo);
        if (containsSpecialChar) {
            toast.warning('Votre pseudo ne peut contenir que des lettres et des chiffres.');
            return false;
        }
        return true;
    };

    return (
        <>
            <AuthCheck baseUrl={baseUrl ?? ''} />
            <AuthPage
                title="S'inscrire"
                fields={[
                    { name: 'pseudo', type: 'text', label: 'Pseudo', value: pseudo, onChange: (e) => setPseudo(e.target.value) },
                    { name: 'username', type: 'text', label: 'Email', value: username, onChange: (e) => setUsername(e.target.value) },
                    { name: 'password', type: 'password', label: 'Mot de passe', value: password, onChange: (e) => setPassword(e.target.value) },
                    { name: 'consent', type: 'checkbox', label: '', value: "", onChange: (e) => "" },
                ]}
                buttonText="S'inscrire"
                onSubmit={handleSubmit}
                additionalContent={
                    <div className='connect_with'>
                        <p>Se connecter avec</p>
                        <GoogleOutlined />
                    </div>
                }
                
            />
        </>
    );
};

export default Register;

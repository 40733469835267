import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { CascaderProps, Cascader } from 'antd';
import DropdownList from '../dropdown/DropdownList';
import { useLocation } from 'react-router-dom';
import Input from '../form/Input';

interface AuthFormProps {
    title: string;
    fields: { 
        name: string;
        type: string;
        label: string;
        value: string;
        onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    }[];
    buttonText: string;
    onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
    additionalContent?: React.ReactNode;
}

const AuthForm: React.FC<AuthFormProps> = ({ title, fields, buttonText, onSubmit, additionalContent }) => {
    const location = useLocation().pathname;

    interface Option {
        value: string;
        label: string | JSX.Element; // Ajout de | JSX.Element
        children?: Option[];
    }
      
    const options: Option[] = [

    ];

    if (location === "/login/") {
        options.push({
            value: 'S\'inscrire',
            label: (
              <>
                <DropdownList URL='/register/'>
                <span className="material-symbols-outlined">app_registration</span>
                  {'S\'inscrire'}
                </DropdownList>
              </>
            ),
        });
        options.push({
            value: 'Mot de passe oublié',
            label: (
                <>
                    <DropdownList URL='/forgot-password/'>
                        <span className="material-symbols-outlined">passkey</span>
                        {'Mot de passe oublié'}
                    </DropdownList>
                </>
            ),
        });
    } else {
        options.push({
            value: 'Se connecter',
            label: (
              <>
                <DropdownList URL='/login/'>
                <span className="material-symbols-outlined">app_registration</span>
                  {'Se connecter'}
                </DropdownList>
              </>
            ),
        });
    }
      
    const [text, setText] = useState('Unselect');

    const onChange: CascaderProps<Option>['onChange'] = (_, selectedOptions) => {
        setText(selectedOptions.map((o) => o.label).join(', '));
    };

    const [cascaderOpen, setCascaderOpen] = useState(false);

    const handleDropdownVisibleChange = (open: any) => {
        setCascaderOpen(open);
    };

    return (
        <div className='form_home'>
            <div className='header_form_home'>
                <NavLink to="/">
                    <span className="material-symbols-outlined">keyboard_backspace</span>
                </NavLink>
                <Cascader
                    options={options}
                    onChange={onChange}
                    onDropdownVisibleChange={handleDropdownVisibleChange}
                >
                    <span className={`material-symbols-outlined ${cascaderOpen ? 'rotate' : ''}`}>
                      more_horiz
                    </span>
                </Cascader>
            </div>
            <div className='form'>
                <div className='flex'>
                    <h2>{title}</h2>
                </div>
                <br />
                <form onSubmit={onSubmit}>
                    {fields.map((field, index) => (
                        <div key={index}>
                            <Input
                                key={field.name}
                                type={field.type}
                                name={field.name}
                                label={field.label}
                                value={field.value}
                                onChange={field.onChange}
                                required
                            />
                        </div>
                    ))}
                    <br />
                    <button type="submit" className='signup'>{buttonText}</button>
                </form>
                {additionalContent}
            </div>
        </div>
    );
};

export default AuthForm;

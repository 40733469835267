const handleScroll = () => {
    const image = document.getElementById("image");
    const tableau_de_bord = document.getElementById("tableau_de_bord");
  
    window.addEventListener("scroll", function () {
        let scrollPosition = window.scrollY; // Position de défilement de la page

        // Vérifier si la position de défilement est à 2em du haut de la page
        if (scrollPosition < 10) {
            // Si oui, définir la hauteur de l'image à 5em
            if (image) {
                image.style.height = "5em";
                image.style.width = "5em";
            }
            if (tableau_de_bord) {
                tableau_de_bord.style.transition = "margin-left 0.3s ease-in-out";
                tableau_de_bord.style.marginLeft = "3em";
            }
        } else {
            // Sinon, définir la hauteur de l'image à 3em (ou la taille d'origine)
            if (image) {
                image.style.height = "3em";
                image.style.width = "3em";
            }
            if (tableau_de_bord) {
                tableau_de_bord.style.transition = "margin-left 0.3s ease-in-out";
                tableau_de_bord.style.marginLeft = "5em";
            }
        }
    });
};
  
export default handleScroll;
  
import { NavLink, useLocation } from 'react-router-dom';
import Button from '../button/ButtonRound';
import { Tooltip } from 'antd'

const NavBarDesktop = () => {
    const location = useLocation().pathname;

    return (
        <div>
            <div className='bloc_navbar'>
                <ul>
                    <NavLink to='/home/dashboard/' className={(nav) => (nav.isActive || location == "/home/list-all/" ? "nav-active" : "")}>
                        <li>
                            <Tooltip
                                title="Tableau de bord"
                                trigger='hover'
                                placement='right'
                            >
                                <Button>
                                    <span className="material-symbols-outlined">home</span>
                                </Button>
                            </Tooltip>
                        </li>
                    </NavLink>
                    <NavLink to='/history/' className={(nav) => (nav.isActive ? "nav-active" : "")}>
                        <li>
                            <Tooltip
                                title="Historique"
                                trigger='hover'
                                placement='right'
                            >
                                <Button>
                                    <span className="material-symbols-outlined">history</span> 
                                </Button>
                            </Tooltip>
                        </li>
                    </NavLink>
                    <NavLink to='/list/add/' className={(nav) => (nav.isActive ? "nav-active" : "")}>
                        <li>
                            <Tooltip
                                title="Ajouter une liste"
                                trigger='hover'
                                placement='right'
                            >
                                <Button>
                                    <span className="material-symbols-outlined">forms_add_on</span>
                                </Button>
                            </Tooltip>
                        </li>
                    </NavLink>
                    <NavLink to='/profil/' className={(nav) => (nav.isActive ? "nav-active" : "")}>
                        <li>
                            <Tooltip
                                title="Profil"
                                trigger='hover'
                                placement='right'
                            >
                                <Button>
                                    <span className="material-symbols-outlined">person</span>
                                </Button>
                            </Tooltip>
                        </li>
                    </NavLink>
                    <NavLink to='/setting/' className={(nav) => (nav.isActive ? "nav-active" : "")}>
                        <li>
                            <Tooltip
                                title="Options"
                                trigger='hover'
                                placement='right'
                            >
                                <Button>
                                    <span className="material-symbols-outlined">settings</span>                            
                                </Button>
                            </Tooltip>
                        </li>
                    </NavLink>
                </ul>
            </div>
        </div>
    );
};

export default NavBarDesktop;
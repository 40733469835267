import NavBarMobile from '../../components/navigation/NavBarMobile'
import NavBarDesktop from '../../components/navigation/NavBarDesktop';
import HeaderDesktop from '../../components/header/HeaderDesktop';
import HeaderMobile from '../../components/header/HeaderMobile';
import CardsList from '../../components/cards/CardsList';
import ResumeNav from '../../components/navigation/ResumeNav';
import { NavLink } from 'react-router-dom';
import ButtonRoundHeader from '../../components/button/ButtonRoundHeader';
import ButtonLong from '../../components/button/ButtonLong';
import { Tooltip } from 'antd';
import { useState, useEffect } from 'react';
import Axios from '../../setting/CallerBack';
import axios from 'axios';
import { SpinLoading } from 'antd-mobile';
import { DemoBlock } from '../demos-util';

const HomeDashboard = () => {
    let hasRun = false;
    const baseUrl = Axios.defaults.baseURL;
    const [data, setData] = useState([]);
    const [userId, setUserId] = useState();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (!hasRun) {
            const fetchData = async () => {
                try {
                    // Attendre le changement de token
                    /*await new Promise(resolve => {
                        const handleTokenChange = () => {
                            resolve(void 0);
                            window.removeEventListener('tokenChange', handleTokenChange);
                        };
                        
                        const timeoutId = setTimeout(() => {
                            handleTokenChange();
                        }, 400); // 3 secondes en millisecondes
                        
                        window.addEventListener('tokenChange', () => {
                            clearTimeout(timeoutId); // Annule le timeout si le tokenChange se produit avant les 3 secondes
                            handleTokenChange();
                        });
                    });   */    
                    // Récupérer le token mis à jour
                    const token = localStorage.getItem("token");

                    // Utiliser le token dans les en-têtes de requête
                    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        
                    // Effectuez l'appel API
                    const listsResponse = await axios.get(baseUrl + '/api/home/catalogues');
                    setData(listsResponse.data[0]);
                    setUserId(listsResponse.data[1])
                    setIsLoading(false);
                } catch (error) {
                    console.error(error);
                    setIsLoading(false);
                }
            };
            fetchData();
            hasRun = true;
        }
    },[]);

    if (isLoading) {
        return (
            <div>
                <NavBarMobile />
                <NavBarDesktop />
                <HeaderDesktop />
                <HeaderMobile />
                <DemoBlock title=''>
                    <SpinLoading style={{ '--size': '3em' }} />
                </DemoBlock>
            </div>
        )
    }
    return (
        <div>
            <NavBarMobile />
            <NavBarDesktop />
            <HeaderDesktop />
            <HeaderMobile />
            <section className='page_content'>
                <ResumeNav />
                <div className='header_list_home'>
                    <h1>Listes</h1>
                    <div className='header_list_home_action'>
                        <NavLink to='/list/add/'>
                            <Tooltip
                                title="Ajout d'une liste"
                                trigger='hover'
                                placement='bottom'
                            >
                                <ButtonRoundHeader>
                                    <span className="material-symbols-outlined">forms_add_on</span>
                                </ButtonRoundHeader>   
                            </Tooltip>                       
                        </NavLink>
                        <NavLink to='/home/list-all/'>
                            <ButtonLong>
                                Voir tout
                            </ButtonLong>
                        </NavLink>
                    </div>
                </div>
                <CardsList data={data} userId={userId}/>
            </section>
        </div>
    );
};

export default HomeDashboard;
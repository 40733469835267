import React, { ReactNode, useState } from 'react';

interface InputProps {
    type: string;
    name: string;
    label: string;
    required?: boolean;
    value: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Input: React.FC<InputProps> = ({ type, name, label, required = false, value, onChange }) => {
    const [showPassword, setShowPassword] = useState(false);
    
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const passwordInputType = showPassword ? 'text' : 'password';

    return (
        <div className="container">
            {type === 'password' ? (
                <div className='password_visible'>
                    <input required={required} type={passwordInputType} name={name} value={value} onChange={onChange} className="input" />
                    <label className="label">{label}</label>
                    <button className="visible_password" type="button" onClick={togglePasswordVisibility}>
                        {showPassword ? <span className="material-symbols-outlined">visibility_off</span> : <span className="material-symbols-outlined">visibility</span>}
                    </button>
                </div>
            ) : type === 'checkbox' ? (
                <>
                    <div className='bloc_consentement'>
                      <label className='label_consentement container_label' htmlFor='consentementCheckbox'>
                        <input type="checkbox" className='checkbox_inscription' id='consentementCheckbox' required/>
                        <div className="checkmark"></div>
                        J'autorise Elephanti Memoria à collecter les données essentielles à son fonctionnement.                      
                      </label>
                    </div>
                </>
            ) : (
                <>
                    <input required={required} type={type} name={name} value={value} onChange={onChange} className="input" />
                    <label className="label">{label}</label>
                </>
            )}
        </div>
    );
};

export default Input;

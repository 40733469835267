import { Card, Cascader } from 'antd';
import { NavLink } from 'react-router-dom';
import type { CascaderProps } from 'antd';
import { useState } from 'react';
import ButtonLong from '../button/ButtonLong';
import DropdownList from '../dropdown/DropdownList';
import favoriteCatalogue from '../favorite/FavoriteCatalogue';

interface PersonData {
  id: number;
  title: string;
  user: string;
  image: string;
  cardsCount: number;
  counter: number;
  userId: number;
  // Autres propriétés selon vos données
}

interface CardsListProps {
  data: PersonData[]; // Par exemple, un tableau de données sur les personnes
  userId: any;
}

const CardsList: React.FC<CardsListProps> = ({ data, userId }) => {
      interface Option {
        value: string;
        label: string | JSX.Element; // Ajout de | JSX.Element
        children?: Option[];
      }
      
      // Generate options based on userId
      const generateOptions = (itemuserId: number, itemId:number): Option[] => {
        const options: Option[] = [

        ];

        // Customize options based on userId
        if (userId === itemuserId) {
          // Add specific options for user with userId 1
          options.push(
            {
              value: 'Modifier la liste',
              label: (
                <>
                  <DropdownList URL={`/modification/list/${itemId}`}>
                    <span className="material-symbols-outlined">box_edit</span>
                    {'Modifier la liste'}
                  </DropdownList>
                </>
              )
            },
          );
        } else {
          options.push(
            {
              value: 'Quiz',
              label: (
                <>
                  <DropdownList URL={`/list-all/${userId}`}>
                    <div className='heart-container-block'>
                      <div className="svg-container">
                        <svg height="20px" xmlns="http://www.w3.org/2000/svg" className="svg-outline" viewBox="0 0 24 24">
                          <path d="M17.5,1.917a6.4,6.4,0,0,0-5.5,3.3,6.4,6.4,0,0,0-5.5-3.3A6.8,6.8,0,0,0,0,8.967c0,4.547,4.786,9.513,8.8,12.88a4.974,4.974,0,0,0,6.4,0C19.214,18.48,24,13.514,24,8.967A6.8,6.8,0,0,0,17.5,1.917Zm-3.585,18.4a2.973,2.973,0,0,1-3.83,0C4.947,16.006,2,11.87,2,8.967a4.8,4.8,0,0,1,4.5-5.05A4.8,4.8,0,0,1,11,8.967a1,1,0,0,0,2,0,4.8,4.8,0,0,1,4.5-5.05A4.8,4.8,0,0,1,22,8.967C22,11.87,19.053,16.006,13.915,20.313Z"></path>
                        </svg>
                      </div>
                    </div>
                    {'Quiz'}
                  </DropdownList>
                </>
              )
            },
          );
        }
        return options;
      };

      const [text, setText] = useState('Unselect');
    
      const onChange: CascaderProps<Option>['onChange'] = (_, selectedOptions) => {
        setText(selectedOptions.map((o) => o.label).join(', '));
      };

      // Initialize an object to store the open state for each card
      const [cascaderOpenState, setCascaderOpenState] = useState<{ [key: number]: boolean }>({});

      // Function to handle the dropdown visible change for a specific card
      const handleDropdownVisibleChange = (index: number, open: boolean) => {
        setCascaderOpenState(prevState => ({
          ...prevState,
          [index]: open,
        }));
      };
  
      return (
        <div>
          {data.map((item, index) => {
            const options = generateOptions(item.userId, item.id);
            const cascaderOpen = cascaderOpenState[index] || false;
            return (
              <Card key={index}
                title={
                  <div className="card_action">
                    <p>{item.title}</p>
                    <Cascader
                      options={options}
                      onChange={onChange}
                      onDropdownVisibleChange={(open) => handleDropdownVisibleChange(index, open)}
                    >
                      <span className={`material-symbols-outlined ${cascaderOpen ? 'rotate' : ''}`}>
                        more_horiz
                      </span>
                    </Cascader>
                  </div>
                }
                bordered={false}
              >
                <NavLink to={`/list/${item.id}/`}></NavLink>
                <div className='content_card'>
                  <ButtonLong><span>{item.cardsCount > 1 ? item.cardsCount + " Cartes" : item.cardsCount + " Carte"}</span></ButtonLong>
                  <div className='favoris_carte'>
                    <div className='heart-container-block'>
                      {item.userId !== userId && ( // Affiche la case à cocher uniquement si l'utilisateur est différent de l'utilisateur connecté
                        <input id={`Give-It-An-Id-${item.id}`} className="checkbox" type="checkbox" onChange={(event) => favoriteCatalogue(event, item.id)} />
                      )}
                      <div className="svg-container">
                        <svg height="2em" xmlns="http://www.w3.org/2000/svg" className="svg-outline" viewBox="0 0 24 24">
                          <path d="M17.5,1.917a6.4,6.4,0,0,0-5.5,3.3,6.4,6.4,0,0,0-5.5-3.3A6.8,6.8,0,0,0,0,8.967c0,4.547,4.786,9.513,8.8,12.88a4.974,4.974,0,0,0,6.4,0C19.214,18.48,24,13.514,24,8.967A6.8,6.8,0,0,0,17.5,1.917Zm-3.585,18.4a2.973,2.973,0,0,1-3.83,0C4.947,16.006,2,11.87,2,8.967a4.8,4.8,0,0,1,4.5-5.05A4.8,4.8,0,0,1,11,8.967a1,1,0,0,0,2,0,4.8,4.8,0,0,1,4.5-5.05A4.8,4.8,0,0,1,22,8.967C22,11.87,19.053,16.006,13.915,20.313Z">
                          </path>                        
                        </svg>
                        <svg height="2em" xmlns="http://www.w3.org/2000/svg" className={`svg-filled svg-filled-block svg-filled-block-liste-${item.id}`} viewBox="0 0 24 24">
                          <path d="M17.5,1.917a6.4,6.4,0,0,0-5.5,3.3,6.4,6.4,0,0,0-5.5-3.3A6.8,6.8,0,0,0,0,8.967c0,4.547,4.786,9.513,8.8,12.88a4.974,4.974,0,0,0,6.4,0C19.214,18.48,24,13.514,24,8.967A6.8,6.8,0,0,0,17.5,1.917Z"></path>
                        </svg>
                      </div>
                    </div>
                    <p id={`compteur_liste_${item.id}`}>{item.counter}</p>
                  </div>
                </div>
                <div className='bottom_card'>
                  <img height="24px" src={item.image} alt="photo de profil" />
                  <p>{item.user}</p>
                </div>
              </Card>
            );
          })}
        </div>
      );
    };

export default CardsList;
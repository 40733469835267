import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/home/HomeDashboard';
import handleScroll from './javascript/ScrollHeader';
import Login from './pages/authentication/Login';
import Register from './pages/authentication/Register';
import { ToastContainer } from 'react-toastify';
import SlashUrl from './javascript/SlashUrl';
import Start from './pages/authentication/Start';
import AuthGuard from './setting/AuthGuard';
import AddList from './pages/add/AddList';

function App() {
  handleScroll();
  SlashUrl()
  
  return (
    <div className="App">
      <ToastContainer theme='colored'></ToastContainer>
      <BrowserRouter>
        <Routes>
          <Route path="/" Component={Start}/>
          <Route path="/login" Component={Login}/>
          <Route path="/register" Component={Register}/>
          <Route path='/*' element={
            <AuthGuard>
              <Routes>
                <Route path="/home/dashboard" Component={Home}/>
                <Route path="/list/add" Component={AddList}/>
              </Routes>
            </AuthGuard>
          }/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

import React from 'react';

type ButtonProps = {
    children: React.ReactNode;
    className?: string;
    [x: string]: any;
  };
  
  const ButtonRoundHeader = ({ children, className, ...props }: ButtonProps) => {
    return (
      <button className={`buttonRoundHeader ${className}`} {...props}>
        {children}
      </button>
    );
  };

export default ButtonRoundHeader;
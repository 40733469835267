import React, { ReactNode } from 'react';
import AuthForm from './AuthForm';

interface AuthPageProps {
    title: string;
    fields: { 
        name: string, 
        type: string, 
        label: string,
        value: string,
        onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
    }[];
    buttonText: string;
    onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
    additionalContent?: ReactNode;
}

const AuthPage: React.FC<AuthPageProps> = ({ title, fields, buttonText, onSubmit, additionalContent }) => {
    return (
        <div className='authentication_page'>
            <div className="logo">
                <div className='logo_site'>
                    <img src="/logo-elephanti-memoria.svg" alt="app_logo"></img>
                    <div className='title_site'>
                        <div className="content">
                            <h1>ELEPHANTI</h1>
                            <h1>ELEPHANTI</h1>
                        </div>
                        <div className="content-1">
                            <h1>MEMORIA</h1>
                            <h1>MEMORIA</h1>
                        </div>
                    </div>
                </div>
                <div className="container-fluid" >
                    <span className="letter">Découvrir</span>
                    <div className="scroll-down__line">
                        <span/>
                    </div>
                    <span className="letter">scroll</span>
                </div>
            </div>
            <div className='home'>
                <AuthForm
                    title={title}
                    fields={fields}
                    buttonText={buttonText}
                    onSubmit={onSubmit}
                    additionalContent={additionalContent}
                />
            </div>
        </div>
    );
};

export default AuthPage;
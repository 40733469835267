import { useState, useEffect } from 'react';
import { useLocation, NavLink, useNavigate } from 'react-router-dom';
import Button from '../button/ButtonRound';
import { CapsuleTabs } from 'antd-mobile';
import ButtonTransparent from '../button/ButtonTransparent';

const HeaderMobile = () => {
    const navigate = useNavigate();
    const location = useLocation().pathname;
    const [activeKey, setActiveKey] = useState<string>('0');

    useEffect(() => {
        if (location === '/home/dashboard/') {
            setActiveKey('1');
        } else if (location === '/home/list-all/') {
            setActiveKey('2');
        }
    }, [location]);

    const handleTabsChange = (key: string) => {
        if (key === '1') {
            navigate('/home/dashboard/');
        } else if (key === '2') {
            navigate('/home/list-all/');
        }
    };

    // Vérifier si la première partie du pathname est 'home'
    const showFirstList = location.split('/')[1] !== 'home';

    return (
        <div>
            <div className='navbar_mobile'>
                {showFirstList && (
                    <ul className='list_header_back'>
                        <NavLink to='' onClick={() => window.history.back()}>
                            <li>
                                <ButtonTransparent>
                                    <span className="material-symbols-outlined">arrow_back_ios</span>                            
                                </ButtonTransparent>
                            </li>
                        </NavLink>
                    </ul>
                )}
                <NavLink to='/home/dashboard/'>
                    <img src="/logo-elephanti-memoria.svg" id="image" />
                </NavLink>
                <ul className='list_header'>
                    <NavLink to='/search/' onClick={() => { const newURL = `/search/`; window.history.pushState({}, '', newURL); window.location.reload(); }} className={(nav) => (nav.isActive ? "nav-active" : "")}>
                        <li>
                            <Button>
                                <span className="material-symbols-outlined">search</span>
                            </Button>
                        </li>
                    </NavLink>
                </ul>
            </div>
            <CapsuleTabs activeKey={activeKey} onChange={handleTabsChange}>
                <CapsuleTabs.Tab title={
                    <>
                        <span className="material-symbols-outlined">dashboard</span>
                        {"Tableau de bord"}
                    </>
                } key='1'>
                </CapsuleTabs.Tab>
                <CapsuleTabs.Tab title={
                    <>
                        <span className="material-symbols-outlined">format_list_bulleted</span>
                        {"Listes"}
                    </>
                } key='2'>
                </CapsuleTabs.Tab>
            </CapsuleTabs>
        </div>
    );
};

export default HeaderMobile;
import Axios from '../../setting/CallerBack';
import AuthCheck from '../../components/authentication/AuthCheck';
import { NavLink } from 'react-router-dom';
import { Cascader, CascaderProps } from 'antd';
import DropdownList from '../../components/dropdown/DropdownList';
import { useLocation } from 'react-router-dom';
import { useState } from 'react';
import { GoogleOutlined } from '@ant-design/icons';

const Start = () => {
    const baseUrl = Axios.defaults.baseURL;

    const location = useLocation().pathname;

    interface Option {
        value: string;
        label: string | JSX.Element; // Ajout de | JSX.Element
        children?: Option[];
    }
      
    const options: Option[] = [

    ];

    if (location === "/login/") {
        options.push({
            value: 'S\'inscrire',
            label: (
              <>
                <DropdownList URL='/register/'>
                <span className="material-symbols-outlined">app_registration</span>
                  {'S\'inscrire'}
                </DropdownList>
              </>
            ),
        });
        options.push({
            value: 'Mot de passe oublié',
            label: (
                <>
                    <DropdownList URL='/forgot-password/'>
                        <span className="material-symbols-outlined">passkey</span>
                        {'Mot de passe oublié'}
                    </DropdownList>
                </>
            ),
        });
    } else {
        options.push({
            value: 'Se connecter',
            label: (
              <>
                <DropdownList URL='/login/'>
                <span className="material-symbols-outlined">app_registration</span>
                  {'Se connecter'}
                </DropdownList>
              </>
            ),
        });
    }
      
    const [text, setText] = useState('Unselect');

    const onChange: CascaderProps<Option>['onChange'] = (_, selectedOptions) => {
        setText(selectedOptions.map((o) => o.label).join(', '));
    };

    const [cascaderOpen, setCascaderOpen] = useState(false);

    const handleDropdownVisibleChange = (open: any) => {
        setCascaderOpen(open);
    };
    return (
        <div className='authentication_page'>
            <AuthCheck baseUrl={baseUrl ?? ''} />
            <div className="logo">
                <div className='logo_site'>
                    <img src="/logo-elephanti-memoria.svg" alt="app_logo"></img>
                    <div className='title_site'>
                        <div className="content">
                            <h1>ELEPHANTI</h1>
                            <h1>ELEPHANTI</h1>
                        </div>
                        <div className="content-1">
                            <h1>MEMORIA</h1>
                            <h1>MEMORIA</h1>
                        </div>
                    </div>
                </div>
                <div className="container-fluid" >
                    <span className="letter">Découvrir</span>
                    <div className="scroll-down__line">
                        <span/>
                    </div>
                    <span className="letter">scroll</span>
                </div>
            </div>
            <div className='home'>
                <div className='form_home'>
                    <div className='header_form_home'>
                        <NavLink to="/">
                            <span className="material-symbols-outlined">keyboard_backspace</span>
                        </NavLink>
                        <Cascader
                            options={options}
                            onChange={onChange}
                            onDropdownVisibleChange={handleDropdownVisibleChange}
                        >
                            <span className={`material-symbols-outlined ${cascaderOpen ? 'rotate' : ''}`}>
                            more_horiz
                            </span>
                        </Cascader>
                    </div>
                    <div className='form'>
                        <br />
                        <NavLink to="/login/">
                            <button className='signup'>Se connecter</button>
                        </NavLink>
                        <NavLink to="/register/" className="register">
                            <button className='signup'>S'inscrire</button>
                        </NavLink>
                        <div className='connect_with'>
                            <p>Se connecter avec</p>
                            <GoogleOutlined/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Start;
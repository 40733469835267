const slashUrl = () => {
    const currentUrl = window.location.pathname;

    // Vérifiez si l'URL actuelle n'a pas déjà un slash à la fin
    if (currentUrl.charAt(currentUrl.length - 1) !== '/') {
      // Ajoutez un slash à la fin de l'URL
      const newPath = `${currentUrl}/`;
    
      // Remplacez l'URL actuelle avec la nouvelle URL
      window.history.replaceState(null, '', newPath);
    }
};
  
export default slashUrl;
import { useLocation, NavLink } from 'react-router-dom';
import Button from '../button/ButtonRound';

const NavBarMobile = () => {
    const location = useLocation().pathname;

    return (
        <div>
            <div className='bloc_navbar_mobile'>
                <ul>
                    <NavLink to='/home/dashboard/' className={(nav) => (nav.isActive || location == "/home/list-all/" ? "nav-active" : "")}>
                        <li>
                            <Button>
                                <span className="material-symbols-outlined">home</span>
                            </Button>
                        </li>
                    </NavLink>
                    <NavLink to='/history/' className={(nav) => (nav.isActive ? "nav-active" : "")}>
                        <li>
                            <Button>
                                <span className="material-symbols-outlined">history</span>
                            </Button>
                        </li>
                    </NavLink>
                    <NavLink to='/list/add/' className={(nav) => (nav.isActive ? "nav-active" : "")}>
                        <li>
                            <Button>
                                <span className="material-symbols-outlined">forms_add_on</span>
                            </Button>
                        </li>
                    </NavLink>
                    <NavLink to='/profil/' className={(nav) => (nav.isActive ? "nav-active" : "")}>
                        <li>
                            <Button>
                                <span className="material-symbols-outlined">person</span>
                            </Button>
                        </li>
                    </NavLink>
                    <NavLink to='/setting/' className={(nav) => (nav.isActive ? "nav-active" : "")}>
                        <li>
                            <Button>
                                <span className="material-symbols-outlined">settings</span>                            
                            </Button>
                        </li>
                    </NavLink>
                </ul>
            </div>
        </div>
    );
};

export default NavBarMobile;
import { useLocation, NavLink } from 'react-router-dom';
import ButtonLong from '../button/ButtonLong';
import Button from '../button/ButtonRound';
import { Tooltip } from 'antd';

const HeaderDesktop = () => {
    const location = useLocation().pathname;

    return (
        <div>
            <div className='navbar_desktop'>
                <a href='/home/dashboard/'>
                    <img src="/logo-elephanti-memoria.svg" id="image" />
                </a>
                <NavLink to='/home/dashboard/' className={(nav) => (nav.isActive ? "nav-active" : "")}>
                    <ButtonLong>
                        <span className="material-symbols-outlined">dashboard</span>                        
                        Tableau de bord
                    </ButtonLong>
                </NavLink>
                <NavLink to='/home/list-all/' className={(nav) => (nav.isActive ? "nav-active" : "")}>
                    <ButtonLong>
                        <span className="material-symbols-outlined">format_list_bulleted</span>                       
                        Listes
                    </ButtonLong>
                </NavLink>
                <ul className='liste_header'>
                    <NavLink to='/search/' onClick={() => {const newURL = `/search/`;window.history.pushState({}, '', newURL);window.location.reload();}}className={(nav) => (nav.isActive ? "nav-active" : "")}>
                        <li>
                            <Tooltip
                                title="Recherche"
                                trigger='hover'
                                placement='bottom'
                            >
                                <Button>
                                    <span className="material-symbols-outlined">search</span>
                                </Button>
                            </Tooltip>
                        </li>
                    </NavLink>
                </ul>
            </div>
        </div>
    );
};

export default HeaderDesktop;